export default Object.freeze([
  {
    id: 'yv79yfecf',
    spacing: {
      margin: '40px 0 20px',
      padding: '0',
    },
    columns: [
      {
        id: '4yjc7f67i',
        horizontalAlignment: 'CENTER',
        contentBlocks: [
          {
            id: 'fkk7uoql4',
            name: 'SanaText',
            model: {
              key: 'PageNotFound_Title',
              styleMode: 'Heading1',
            },
            horizontalAlignment: 'CENTER',
            spacing: {
              padding: '0 0 10px',
            },
          },
          {
            id: 'q6veagiiv',
            name: 'SanaText',
            model: {
              key: 'PageNotFound_IntroText',
              styleMode: 'None',
            },
            horizontalAlignment: 'CENTER',
          },
          {
            id: 'ocf2xusj9',
            name: 'LinkButton',
            model: {
              buttonText: 'GoToHomePage',
              isSanaText: true,
              link: {
                to: {
                  routeName: '_HomePage',
                },
              },
            },
            spacing: {
              padding: '32px 0 0',
            },
          },
        ],
      },
    ],
  },
]);
