export default Object.freeze([
  {
    id: 'im2psinty',
    spacing: {
      margin: '80px 0 20px',
      padding: '0',
    },
    columns: [
      {
        id: 'c128n59xp',
        horizontalAlignment: 'CENTER',
        contentBlocks: [
          {
            id: 'fv376ej3u',
            name: 'SanaText',
            model: {
              key: 'PageNotFound_Title',
              styleMode: 'Heading1',
            },
            horizontalAlignment: 'CENTER',
            spacing: {
              padding: '0 0 10px',
            },
          },
          {
            id: '3xlk15zus',
            name: 'SanaText',
            model: {
              key: 'PageNotFound_IntroText',
              styleMode: 'None',
            },
            horizontalAlignment: 'CENTER',
          },
          {
            id: '6gggcj08o',
            name: 'LinkButton',
            model: {
              buttonText: 'GoToHomePage',
              isSanaText: true,
              link: {
                to: {
                  routeName: '_HomePage',
                },
              },
            },
            spacing: {
              padding: '32px 0 0',
            },
          },
        ],
      },
    ],
  },
]);
